import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div>
      <img src={logo} alt="logo" width="40%" height="40%" />
    </div>
  );
}

export default App;
